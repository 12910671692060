import React from 'react';
import './App.scss';
import { getCurrentManager } from './API/ManagerList';
import UserList from './Components/UserList/UserList';
import ManagerList from './Components/ManagerList/ManagerList';
import CaptureList from './Components/CaptureList/CaptureList';
import SettingsList from './Components/SettingsList/SettingsList';
import LoginPage from './Components/LoginPage/LoginPage';
import Messages from './Components/Messages/Messages';
import Ads from './Components/Ads/Ads';
import classNames from 'classnames';
import ManagerToken from './API/ManagerToken';

class App extends React.Component {
  state = {
    currentPage: 'users',
    isSignedIn: false,
    currentManager: {
      access: {
        ads: true,
        capture: true,
        manager: true,
        message: true,
        settings: true,
        user: true,
      },
    },
  }

  componentDidMount() {
    if (JSON.parse(window.localStorage.getItem('token'))) {
      ManagerToken.value = JSON.parse(window.localStorage.getItem('token'));
      this.signIn();
    }
  }

  pageSelector() {
    switch(this.state.currentPage) {
      case "users":
        return <UserList />;
      case "ads":
        return <Ads />;
      case "capture":
        return <CaptureList />;
      case "messages":
        return <Messages />;
      case "managers":
        return <ManagerList />;
      case "settings":
        return <SettingsList />;
      default:
        return "";
    }
  }

  signIn(id, page = 1) {
    const response = getCurrentManager();
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while uploading manager info.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        console.log(dataJSON);
        this.setState({currentManager: dataJSON});
      }
    ).catch(alert);
    this.setState({isSignedIn: true});
  }

  logOut() {
    window.localStorage.setItem('token', JSON.stringify(''));
    this.setState({isSignedIn: false});
  }

  render() {
    const { currentManager } = this.state;
    return (
      <div className="App">
        {
          this.state.isSignedIn
          ? <>
              <div className="sidebar">
                <div className="sidebar__header">
                  <div className="sidebar__header--image"></div>
                  FishTools
                </div>
                <div className="sidebar__manager-info">
                  {
                    currentManager.photo
                    ? <div className={classNames("manager-info__image", {placeholder: currentManager.photo === null})}  style={{backgroundImage:'url(' + currentManager.photo + `)`}}></div>
                    : <div className={classNames("manager-info__image", {placeholder: currentManager.photo === null})}></div>
                  }
                  <div className="manager-info__additional">
                    <div className="manager-name">{currentManager.name}</div>
                    <div className="manager-email">{currentManager.email}</div>
                  </div>
                </div>
                <div className="sidebar__buttons">
                  <div className="buttons-container">
                    <div
                      className={classNames('button','users',
                      {selected: this.state.currentPage === 'users','disabled-button': this.state.currentManager.access.user === false})}
                      onClick={() => this.setState({currentPage: 'users'})}
                    >
                      Users
                    </div>
                    <div
                      className={classNames('button','ads',
                      {selected: this.state.currentPage === 'ads','disabled-button': this.state.currentManager.access.ads === false})}
                      onClick={() => this.setState({currentPage: 'ads'})}
                    >
                      Ads
                    </div>
                    <div
                      className={classNames('button','capture',
                      {selected: this.state.currentPage === 'capture','disabled-button': this.state.currentManager.access.capture === false})}
                      onClick={() => this.setState({currentPage: 'capture'})}
                    >
                      Capture
                    </div>
                    <div
                      className={classNames('button','messages',
                      {selected: this.state.currentPage === 'messages','disabled-button': this.state.currentManager.access.message === false})}
                      onClick={() => this.setState({currentPage: 'messages'})}
                    >
                      Messages
                    </div>
                    <div
                      className={classNames('button','managers',
                      {selected: this.state.currentPage === 'managers','disabled-button': this.state.currentManager.access.manager === false})}
                      onClick={() => this.setState({currentPage: 'managers'})}
                    >
                      Managers
                    </div>
                    <div className="devider"></div>
                    <div
                      className={classNames('button','settings',
                      {selected: this.state.currentPage === 'settings','disabled-button': this.state.currentManager.access.settings === false})}
                      onClick={() => this.setState({currentPage: 'settings'})}
                      
                    >
                      Settings
                    </div>
                  </div>
                  <div
                      className={'button logout'}
                      onClick={() => this.logOut()}
                    >
                      Exit
                  </div>
                </div>
              </div>
              <div className="content">
                {
                  this.pageSelector()
                }
              </div>
            </>
          : <LoginPage
                signIn={this.signIn.bind(this)}
            />
        }
      </div>
    );
  }
}

export default App;
