import React from "react";
import './Messages.scss';
import MessageForm from "./MessageForm/MessageForm";
import { getMessages } from '../../API/Messages';

const PageSize = 8;

class Messages extends React.Component {
  state = {
    settingsObj: {},
    searchQuery: '',
    showCountriesList: true,
    countriesList: [],
    placesList: [],
    currentCountryId: 0,
    currentPage: 1,
    formIsVisible: false,
    place: {},
  }

  componentDidMount() {
    this.downloadMessages();
  }

  downloadMessages(search = this.state.searchQuery) {
    const response = getMessages(`?q=${search}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading messages list.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        console.log(dataJSON);
        this.setState({countriesList: dataJSON});
      }
    ).catch(alert);
  }

  setSearchQuery(e) {
    this.setState({
      searchQuery: e.target.value,
      currentPage: 1,
    });
    this.downloadMessages(e.target.value);
  }


  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  showForm() {
    this.setState({ formIsVisible: true});
  }

  closeForm() {
    this.setState({formIsVisible: false});
    this.downloadMessages();
  }


  render() {
    const { currentPage, countriesList } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = countriesList.slice(firstPageIndex, lastPageIndex);

    return(
      <div className="messages-list">
        <div className="header">
          <input
            type="search"
            className="header__search"
            placeholder="Search all..."
            value={this.state.searchQuery}
            onChange={(e) => this.setSearchQuery(e)}
            disabled={!this.state.showCountriesList}
          />
        </div>
        <div className="list">
          <div
            className="list__header"
          >
            <div className="title">Messages</div>
            <div
              className="button"
              onClick={() => this.showForm()}
            >New message</div>
            {
              this.state.formIsVisible &&
              <MessageForm
                handleClose={this.closeForm.bind(this)}
              />
            }
          </div>
          <div className="messages">
            {
              countriesList.map(item => {
                return(
                  <div className="message">
                    <div className="message__info">
                      <div className="date">{`${item.created_at.split('T').shift()}`}</div>
                      <div className="theme">{item.theme}</div>
                      <div className="place">{item.country.title}</div>
                    </div>
                    <div className="message__text">
                      <div className="title">{item.theme}</div>
                      <div className="text">{item.text}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Messages;