import React from "react";
import './LoginPage.scss';
import { login, sendCode } from '../../API/Login.js';
import ManagerToken from "../../API/ManagerToken";
import classNames from "classnames";

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    showPassword: false,
    incorrectCredentials: false,
  }

  handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('code', this.state.password);

    const response = login(formData);
    response.then(
      data => {

        switch(data.status) {
          case 403:
            this.setState({incorrectCredentials: true});
            throw new Error('Incorrect authentication credentials.');
            return;
          case 400:
            throw new Error('Please enter a valid email and a password in a format: 999999');
          default:
            break;
        }
        
        if (!data.ok) {
          throw new Error('An error has occurred while logging in.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        if (dataJSON) {
          ManagerToken.value = `Token ${dataJSON.token}`;
          window.localStorage.setItem('token', JSON.stringify(`Token ${dataJSON.token}`));
          this.props.signIn(dataJSON.user_id);
        }
      }
    ).catch(alert);
  }

  sendCode() {

    if (this.state.email.length === 0) {
      alert('Please enter your email.');
      return;
    }

    const formData = new FormData();
    formData.append('email', this.state.email);

    const response = sendCode(formData);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while sending a code');
        }
        alert('A code has been sent to your email.')
      }
    ).catch(alert);
  }

  changeVisibility() {
    this.setState((state) => ({showPassword: !state.showPassword}))
  }

  render() {
    console.log(this.state.incorrectCredentials);
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="logo"></div>
          <div className="title">Sign In</div>
          <form
            className="login-form"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="label">Email</div>
            <input
              type="email"
              className={classNames('text-input',{incorrect: this.state.incorrectCredentials})}
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value,incorrectCredentials: false})}
              placeholder='Email'
            />
            <div className="label">Password</div>
            <div className={classNames('text-input password',{incorrect: this.state.incorrectCredentials})}>
              <input
                type={this.state.showPassword ? "text" : "password"}
                className="password-input"
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value,incorrectCredentials:false})}
                placeholder='Password'
              />
              <div
                className="show-button"
                onClick={() => this.changeVisibility()}
              ></div>
            </div>
            <div
              className="send-code"
              onClick={() => this.sendCode()}
            >Send code.</div>
            <button type="submit" className="button">Sign in</button>
          </form>
        </div>
      </div>
    )
  }
}

export default LoginPage;