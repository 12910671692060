const BASE_URL = 'https://api.fishtools.app/admins/login/';

async function login(body) {
  const get = () => {
    return fetch(BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: 'default',
      headers: {
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await get();
}

async function sendCode(body) {
  const get = () => {
    return fetch('https://api.fishtools.app/admins/send/', {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: {
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await get();
}

export { login, sendCode};


