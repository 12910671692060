import React from "react";
import './Ads.scss';
import { getCountries, getPlaces } from "../../API/Ads";
import Pagination from "../Pagination/Pagination";
import classNames from "classnames";
import EditForm from "./EditForm/EditForm";

const PageSize = 14;

class Ads extends React.Component {
  state = {
    settingsObj: {},
    searchQuery: '',
    showCountriesList: true,
    countriesList: [],
    placesList: [],
    currentCountryId: 0,
    currentPage: 1,
    formIsVisible: false,
    place: {},
    countryName: '',
  }

  setSearchQuery(e) {
    this.setState({
      searchQuery: e.target.value,
      currentPage: 1,
    });
    this.downloadCountries(e.target.value);
  }

  componentDidMount() {
    this.downloadCountries();
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  downloadCountries(search = this.state.searchQuery) {
    const response = getCountries(`?q=${search}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading a countries list.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState({countriesList: dataJSON});
      }
    ).catch(alert);
  }

  downloadPlaces(id) {
    const response =  getPlaces (id);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading a ads list.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState({
          placesList: dataJSON,
          showCountriesList: false,
        });
      }
    ).catch(alert);
  }

  showForm(place) {
    this.setState({
      formIsVisible: true,
      place: place,
    });
  }

  closeForm() {
    this.setState({formIsVisible: false});
    this.downloadPlaces(this.state.currentCountryId);
  }


  render() {
    const { currentPage, countriesList } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = countriesList.slice(firstPageIndex, lastPageIndex);

    return(
      <div className="ad-list">
        <div className="header">
          <input
            type="search"
            className="header__search"
            placeholder="Search all..."
            value={this.state.searchQuery}
            onChange={(e) => this.setSearchQuery(e)}
            disabled={!this.state.showCountriesList}
          />
        </div>
        <div className="list">
          <div
            className={classNames("list__header", {"list__header--button": !this.state.showCountriesList})}
            onClick={() => this.setState({showCountriesList: true})}
          >
            <div className="title">Country</div>
            {
              this.state.showCountriesList === false &&
              <div className="country-name">{this.state.countryName}</div>
            }
          </div>
          {
            this.state.showCountriesList 
            ? <div className="grid">
                <div className="grid-list">
                  {
                    currentData.map(item => {
                      return(
                        <div
                          className="grid-item"
                          onClick={() => {this.setState({currentCountryId: item.id, countryName: item.title}); this.downloadPlaces(item.id)}}
                        >
                          <div className="flag" style={{backgroundImage: `url(${item.image})`}}></div>
                          {item.title}
                        </div>
                      )
                    })
                  }
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={countriesList.length}
                    pageSize={PageSize}
                    onPageChange={page => this.setCurrentPage(page)}
                />
              </div>
            : <div className="places-list">
                {
                  this.state.placesList.map(place => {
                    return(
                      <div className="place">
                        <div className="main-info">
                          <div className={classNames("place-name")}>{place.place_name} <div className={classNames('status',{'is_active': place.is_active})}></div></div>
                          <div className="date">{`Valid until ${place.valid_until}`}</div>
                        </div>
                        <div className="logo">
                          <div className="title">Logo</div>
                          <div className="logo__image" style={place.logo && {backgroundImage: `url(${place.logo})`}}></div>
                        </div>
                        <div className="color">
                          <div className="title">Color RGB</div>
                          <div className="color__text">{`${place.colour_rgb}`}</div>
                        </div>
                        <div className="link">
                          <div className="title">Link</div>
                          <div className="link__text">{`${place.link}`}</div>
                        </div>
                        <div className="description">
                          <div className="title">Description</div>
                          <div className="description__text">{`${place.description}`}</div>
                        </div>
                        <div className="edit-button">
                          <div
                            className="button"
                            onClick={() => this.showForm(place)}
                          ></div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  this.state.formIsVisible &&
                  <EditForm
                    handleClose={this.closeForm.bind(this)}
                    place={this.state.place}
                  />
                }
              </div>
          }
        </div>
      </div>
    )
  }
}

export default Ads;