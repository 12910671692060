import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/countries/';

async function getCountries(params = '') {
  const get = () => {
    return fetch (BASE_URL + params, {
      method: "GET",
      mode: 'cors',
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    });
  }

  return await get()
}

async function getPlaces(id) {
  const get = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "GET",
      mode: 'cors',
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    });
  }

  return await get()
}

async function editPlace(id, body) {
  const edit = () => {
    return fetch(`https://api.fishtools.app/admins/place/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await edit();
}


export { getCountries, getPlaces, editPlace}