import React from "react";
import getUsers from "../../API/Users";
import './UserList.scss';
import Pagination from "../Pagination/Pagination";
import { CSVLink } from "react-csv";

const PageSize = 10;

class UserList extends React.Component {
  state = {
    usersArr: [],
    emailOrder: 'desc',
    dateOrder: 'asc',
    searchQuery: '',
    type: '',
    countryIdArray: [],
    countryId: '',
    currentPage: 1,
    count: 0,
    allUsersArr: [],
    csvString: '',
  }

  componentDidMount() {
    this.downloadUsers();
    this.findCountries();
    this.exportUsers();
  }

  downloadUsers(page = this.state.currentPage,
    emailOrder = this.state.emailOrder,
    search = this.state.searchQuery,
    dateOrder = this.state.dateOrder,
    type = this.state.type,
    country = this.state.countryId,
    ) {
    const response = getUsers(`?type=${type}&country=${country}&q=${search}&email=${emailOrder}&date=${dateOrder}&page=${page}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось скачать список пользователей');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        console.log(dataJSON.results);
        if (dataJSON.results) {
          this.setState({
            usersArr: [...dataJSON.results],
            count: dataJSON.count,
          });
        }
      }
    ).catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
    this.downloadUsers(value);
  }

  setSearchQuery(e) {
    this.setState(() => ({searchQuery: e.target.value}));
    this.downloadUsers(this.state.currentPage,this.state.emailOrder,e.target.value);
  }

  // changeEmailOrder() {
  //   switch(this.state.emailOrder) {
  //     case 'asc':
  //       this.setState({emailOrder: 'desc'});
  //       this.downloadUsers(this.state.currentPage,'desc');
  //       break;
  //     case 'desc':
  //       this.setState({emailOrder: 'asc'});
  //       this.downloadUsers(this.state.currentPage,'asc');
  //       break;
  //     default: 
  //       break;
  //   }
  // }

  changeDateOrder() {
    switch(this.state.dateOrder) {
      case 'asc':
        this.setState({dateOrder: 'desc'});
        this.downloadUsers(this.state.currentPage,this.state.emailOrder,this.state.searchQuery,'desc');
        break;
      case 'desc':
        this.setState({dateOrder: 'asc'});
        this.downloadUsers(this.state.currentPage,this.state.emailOrder,this.state.searchQuery,'asc');
        break;
      default: 
        break;
    }
  }

  changeCountry(e) {
    this.setState({
      countryId: e.target.value,
      currentPage: 1,
    });
    this.downloadUsers(
      this.state.currentPage,
      this.state.emailOrder,
      this.state.searchQuery,
      this.state.dateOrder,
      this.state.type,
      e.target.value
    );
  }

  changeSubscription(e) {
    this.setState({
      currentPage: 1,
      type: e.target.value,
    });
    this.downloadUsers(this.state.currentPage,this.state.emailOrder,this.state.searchQuery,this.state.dateOrder,e.target.value);
  }

  findCountries(page = 1) {
    const response = getUsers(`?page=${page}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occured while downloading users.');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        const stateArr = [...this.state.countryIdArray];
        const usersArr = dataJSON.results;
        if (stateArr.length === 0) {
          stateArr.push(usersArr[0].country);
        }

        usersArr.forEach(user => {
          if (stateArr.find(country => country.id !== user.country.id)) {
            stateArr.push(user.country);
          }
        });

        this.setState({countryIdArray: stateArr});
        if (dataJSON.next === null) {
          const uniq = stateArr.filter((item, pos, self) => {
            return self.findIndex(item2=>(item2.id === item.id)) === pos;
          })

          this.setState({countryIdArray: uniq});
        } else {
          this.findCountries(page + 1);
        }
      }
    ).catch(alert);

  }

  exportUsers(page = 1) {
    const response = getUsers(`?page=${page}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occured while downloading users.');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        let stateArr = [...this.state.allUsersArr];
        stateArr = stateArr.concat(dataJSON.results);
        
        this.setState({allUsersArr: stateArr});

        if (dataJSON.next !== null) {
          this.exportUsers(page + 1);
        } else {

          const csvString = [
            ['ID', 'UserName','Email','Country','Date','Type'],
            ...this.state.allUsersArr.map(item => [
              item.id,
              item.name,
              item.email,
              item.country.title,
              item.registration_date,
              item.type
            ])
          ].map(e => e.join(',')).join("\n");

          this.setState({csvString: csvString});
        }
      }
    )
  }

  render() {
    const { currentPage, usersArr } = this.state;
    const currentData = usersArr;
    return(
      <div className="user-list">
        <div className="header">
          <input
            type="search"
            className="header__search"
            placeholder="Search all..."
            value={this.state.searchQuery}
            onChange={(e) => this.setSearchQuery(e)}
          />
        </div>
        <div className="list">
          <div className="list__header">
            <div className="list__header--container">
            <div
              className="title"
            >User catalogue</div>
            <CSVLink
              className={'users-export'}
              filename={"fishtools-users.csv"}
              data={this.state.csvString}
            ></CSVLink>
            </div>
            <div className="select-container">
              <select
                className="subscription-select"
                value={this.state.subscriptionSelect}
                onChange={(e) => this.changeSubscription(e)}
              >
                <option value="">Subscription</option>
                <option value="subscription">Subscribed</option>
                <option value="no_subscription">Unsubscribed</option>
              </select>
              <select
                className="country-select"
                value={this.state.countryId}
                onChange={(e) => this.changeCountry(e)}
              >
                <option value="">All</option>
                {
                  this.state.countryIdArray.map(country => {
                    return(
                      <option value={country.id}>{country.title}</option>
                    );
                  })
                }
              </select>
            </div>
          </div>
          <div className="grid-table">
            <div className="grid-table__header grid">
              <div className="head__user head">User name/ID</div>
              <div 
                className="head__email head"
              >Email</div>
              <div className="head__country head">Country</div>
              <div
                className="head__date head"
                onClick={() => this.changeDateOrder()}
              >Registration date</div>
              <div className="head__user-type head">User type</div>
            </div>
            <div className="table">
              {currentData.map(item => {
                return(
                  <div className="grid-item grid">
                    <div className="user">
                      <div className="user-id">{item.id}</div>
                      <div className="user-name">{item.name}</div>
                    </div>
                    <div className="email">{item.email}</div>
                    <div className="country">{item.country.title}</div>
                    <div className="date">{item.registration_date}</div>
                    <div className="user-type">{item.type}</div>
                  </div>
                )
              })}
            </div>
            <div className="grid-table__nav">
              <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={this.state.count}
                  pageSize={PageSize}
                  onPageChange={page => this.setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserList;