import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/captures/';

async function getCaptures(params = '') {
  const get = () => {
    return fetch(BASE_URL + params, {
      method: "GET",
      mode: 'cors',
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await get();
}

export default getCaptures;