import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/managers/';

async function getManagers(page = '') {
  const get = () => {
    return fetch(BASE_URL + page, {
      method: "GET",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await get();
}

async function deleteManager(id) {
  const del = () => {
    return fetch(BASE_URL + `${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await del();
}

async function createManager(body) {
  const create = () => {
    return fetch(BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await create();
}

async function getCurrentManager() {
  const get = () => {
    return fetch('https://api.fishtools.app/admins/me/', {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await get();
}

export { getManagers, createManager, deleteManager, getCurrentManager };