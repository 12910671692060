import React from "react";
import './EditForm.scss';
import { editPlace } from "../../../API/Ads";
import classNames from "classnames";

class EditForm extends React.Component {
  state = {
    link: this.props.place.link,
    description: this.props.place.description,
    color: this.props.place.colour_rgb,
    date: this.props.place.valid_until,
    photoPreview: this.props.place.logo,
    is_active: this.props.place.is_active,
    photoObject: {},
    is_successfull: false,
  }

  handleSubmit(e) {
    e.preventDefault();
    const { photoObject, link, description, color, date, is_active } = this.state;

    if(color.length !== 6 && (color.length !== 7 || color[0] !== `#`)) {
      alert('Please enter a valid colour in HEX format.');
      return;
    }

    const formData = new FormData();
    if (color.length === 6 ) {
      formData.append('colour_rgb', `#` + color);
    } else {
      formData.append('colour_rgb', color);
    }
    formData.append('logo', photoObject.file);
    formData.append('link', link);
    formData.append('description', description);
    formData.append('valid_until', date);
    formData.append('is_active', is_active);

    if (!('file' in photoObject)) {
      formData.delete('logo');
    }

    const response = editPlace(this.props.place.id, formData);
    response.then(
      data => {

        if (data.status === 400) {
          return data.json();
        }

        if (!data.ok) {
          throw new Error('An error has occurred whild updating an ad.');
        }
        return {};
      }
    ).then(
      dataJSON => {
        this.setState({is_successfull: true});
      }
    ).catch(alert);
  }

  handlePhotoUpload(e) {
    if (!e.target.files[0]) {
      this.setState(() => ({
        photoObject: {},
        photoPreview: '',
      }));
      return;
    }
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = e.target.files[0].name;
    const imageExtension = imageName.split('.').pop();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Прикрепите картинку.');
      return;
    }
    this.setState(() => ({
      photoObject: {file: e.target.files[0]},
      photoPreview: URL.createObjectURL(e.target.files[0]),
    }));
  }

  render() {
    const { link, description, color, date , photoObject, photoPreview } = this.state;
    const { place } = this.props;
    return(
      <>
        <div className="blur__background-form">
        </div>
        <div className={classNames('edit__form',{success: this.state.is_successfull})}>
          {
            this.state.is_successfull
            ? <>
                <div className="header__success">
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <div className="text">
                  Done
                  <div className="sub-text">Changes saved successfully.</div>
                </div>
              </>
            : <>
                <div className="header">
                  <div className="info">
                    <div className="photo__section">
                      <div
                        className="photoPreview"
                        style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : 'none'}}
                      ></div>
                      <label
                        htmlFor="photo"
                        className="label-photo"
                      >
                        Download logo
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="photo"
                        className="input-photo"
                        onChange={(e) => this.handlePhotoUpload(e)}
                      />
                    </div>
                    <div className="info-section">
                      <div className="place-name">{place.place_name}</div>
                      <div className="date">{`Valid until ${place.valid_until}`}</div>
                      <div className="is-active">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={this.state.is_active}
                          onChange={(e) => this.setState({is_active: e.target.checked})}
                        />
                        Is active
                      </div>
                    </div>
                  </div>
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <form
                  action="*"
                  className="form"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  <div className="label">
                    Link
                  </div>
                  <input
                    type="url"
                    className="input-name text-input"
                    placeholder="https://www.pinterest.ru/pin/"
                    required
                    value={link}
                    onChange={(e) => this.setState({link: e.target.value})}
                  />
                  <div className="label">
                    Description
                  </div>
                  <input
                    type="text"
                    className="input-phone text-input"
                    placeholder="Lorem ipsum dolor sit "
                    required
                    value={description}
                    onChange={(e) => this.setState({description: e.target.value})}
                  />
                  <div className="label">
                    Color
                  </div>
                  <input
                    type="text"
                    className="input-email text-input"
                    placeholder="#677899"
                    required
                    value={color}
                    onChange={(e) => this.setState({color: e.target.value})}
                  />
                  <div className="label">
                    Valid until
                  </div>
                  <input
                    type="date"
                    className="input-email text-input"
                    required
                    value={date}
                    onChange={(e) => this.setState({date: e.target.value})}
                  />
                  <button type="submit" className="submit-button">Save</button>
                </form>
              </>
          }
        </div>
      </>
    )
  }
}

export default EditForm;