import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/users/';

async function getUsers (params = '') {
  const get = () => {
    return fetch(BASE_URL + params, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      },
    })
  }

  return await get();
}

export default getUsers;