import React from "react";
import { getManagers } from "../../API/ManagerList";
import './ManagerList.scss';
import ManagerForm from "./ManagerForm/ManagerForm";
import DeletionForm from "./DeletionForm/DeletionForm";
import Pagination from "../Pagination/Pagination";

const PageSize = 8;

class ManagerList extends React.Component {
  state = {
    managerArr: [],
    currentPage: 1,
    count: 0,
    formIsVisible: false,
    deletionIsVisible: false,
    deletionId: 0,
  }

  componentDidMount() {
    this.downloadManagers();
  }

  downloadManagers(page = this.state.currentPage) {
    const response = getManagers(`?page=${page}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occured while downloading managers.');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        if (dataJSON.results) {
          this.setState({
            managerArr: dataJSON.results,
            count: dataJSON.count,
          });
        }
      }
    ).catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  openForm() {
    this.setState({formIsVisible: true});
  }

  closeForm() {
    this.setState({
      formIsVisible: false,
    });
    this.downloadManagers();
  }

  openDeletionForm(id) {
    this.setState({
      deletionIsVisible: true,
      deletionId: id,
    });
  }

  closeDeletionForm() {
    this.setState({deletionIsVisible: false});
    this.downloadManagers();
  }

  render() {
    const { currentPage, managerArr } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = managerArr.slice(firstPageIndex, lastPageIndex);
  
    return(
      <div className="manager-list">
        <div className="list">
          <div className="list__header">
            <div className="title">Managers catalogue</div>
            <div
              className="form-button"
              onClick={() => this.openForm()}
            >Add manager</div>
          </div>
          <div className="grid-table">
            <div className="grid-table__header grid">
              <div className="head__user head">Name</div>
              <div className="head__email head">Email</div>
              <div className="head__phone head">Phone</div>
              <div className="head__action head">Actions</div>
            </div>
            <div className="table">
              {currentData.map(item => {
                return(
                  <div className="grid-item grid">
                    <div className="user">{item.name}</div>
                    <div className="email">{item.email}</div>
                    <div className="phone">{item.phone_number}</div>
                    <div
                      className="action"
                      onClick={() => this.openDeletionForm(item.id)}
                    >Delete</div>
                  </div>
                )
              })}
            </div>
            <div className="grid-table__nav">
              <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={managerArr.length}
                  pageSize={PageSize}
                  onPageChange={page => this.setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
        {
          this.state.formIsVisible &&
          <ManagerForm
            handleClose={this.closeForm.bind(this)}
          />
        }
        {
          this.state.deletionIsVisible &&
          <DeletionForm
            handleClose={this.closeDeletionForm.bind(this)}
            id={this.state.deletionId}
          />
        }
      </div>
    )
  }
}

export default ManagerList;