import React from "react";
import './SettingsItem.scss';
import { editCoefficient } from '../../../API/Settings';

class SettingsItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coefficient: props.coef,
      obj: props.obj,
      isEditing: false,
    }
  }

  edit() {
    const formData = new FormData();
    formData.append(`${this.state.obj}`, this.state.coefficient);

    const response = editCoefficient(formData);

    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while editing coefficient.');
        }
      }
    ).catch(alert); 
  }

  render() {
    const {obj, coefficient} = this.state;
    return(
      <div className="settings-item">
        <div className="header">
          <div className="title">Formula</div>
          {
            this.state.isEditing
            ? <div
                className="send-button button"
                onClick={() => {this.edit(); this.setState({isEditing: false})}}
              ></div>
            : <div
                className="edit-button button"
                onClick={() => this.setState({isEditing: true})}
              ></div>
          }
        </div>
        <div className="coefficient">
          {`${obj.toUpperCase()} = `}
          <input
            type="number"
            className="number-input"
            value={coefficient}
            disabled={!this.state.isEditing}
            onChange={(e) => this.setState({coefficient: e.target.value})}
          />
        </div>
      </div>
    )
  }
}

export default SettingsItem;