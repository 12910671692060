import React from "react";
import './MessageForm.scss';
import { getCountries } from "../../../API/Ads";
import { createMessage } from '../../../API/Messages';
import Select from 'react-select';


import classNames from "classnames";

class MessageForm extends React.Component {
  state = {
    theme: '',
    text: '',
    countriesList: [],
    selectedCountry: '',
    is_successfull: false,
  }

  componentDidMount() {
    this.downloadCountries();
  }

  downloadCountries(search = '') {
    const response = getCountries(`?q=${search}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading a countries list.');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        const tempArr = dataJSON.map(item => {
          return (
            {
              label: item.title,
              value: item.id,
            }
          )
        });
        tempArr.unshift({
          label: 'All users',
          value: 307,
        });
        this.setState({countriesList: tempArr});
      }
    ).catch(alert);
  }

  handleChange(selectedOption) {
    this.setState({selectedCountry: selectedOption.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const {text,theme, selectedCountry} = this.state;
    const formData = new FormData();
    formData.append('theme', theme);
    formData.append('text', text);
    formData.append('country', selectedCountry);

    const response = createMessage(formData);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while creating a message.');
        }

        this.setState({is_successfull: true});
      }
    ).catch(alert);
  }

  render() {
    return(
      <>
        <div className="blur__background-form">
        </div>
        <div className={classNames('message__form',{success: this.state.is_successfull})}>
          {
            this.state.is_successfull
            ? <>
                <div className="header__success">
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <div className="text">
                  Done
                  <div className="sub-text">The message was sent successfully.</div>
                </div>
              </>
            : <>
                <div className="header">
                  New Message
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <form
                  action="*"
                  className="form"
                  onSubmit={(e) => this.handleSubmit(e)}
                >
                  <input
                    type="text"
                    className="text-input"
                    value={this.state.theme}
                    placeholder={'Message theme'}
                    onChange={(e) => this.setState({theme: e.target.value})}
                    required
                  />
                  <textarea 
                    className="text-input text-area"
                    placeholder="Message text"
                    value={this.state.text}
                    onChange={(e) => this.setState({text: e.target.value})}
                    required
                  ></textarea>
                  <div className="label">Choose region</div>
                  <Select
                    className={'react-select-container'}
                    options={this.state.countriesList}
                    onChange={(e) => this.handleChange(e)}
                    required
                  />
                  <button type="submit" className="submit-button">Save</button>
                </form>
              </>
          }
        </div>
      </>
    )
  }
}

export default MessageForm;