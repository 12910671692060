import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/messages/';

async function getMessages(params = '') {
  const get = () => {
    return fetch(BASE_URL + `${params}`, {
      method: "GET",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    });
  }

  return await get();
}

async function createMessage(body) {
  const create = () => {
    return fetch (BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await create();
}

export { getMessages, createMessage };