import React from "react";
import './ManagerForm.scss';
import { createManager } from "../../../API/ManagerList";

class ManagerForm extends React.Component {
  state = {
    name: '',
    phone: '',
    email: '',
    photoPreview: '',
    photoObject: {},
    user: true,
    ads: true,
    capture: true,
    message: true,
    manager: true,
    settings: true,
    
  }

  handleSubmit(e) {
    e.preventDefault();
    const { name, phone, email, photoObject, user, ads, capture, message, manager, settings } = this.state;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone_number', phone);
    formData.append('email', email);
    formData.append('photo', photoObject.file);
    formData.append('user', user);
    formData.append('ads', ads);
    formData.append('capture', capture);
    formData.append('message', message);
    formData.append('manager', manager);
    formData.append('settings', settings);

    if (!('file' in photoObject)) {
      formData.delete('photo');
    }

    const response = createManager(formData);
    response.then(
      data => {
        if (data.status === 400) {
          return data.json();
        }

        if (!data.ok) {
          throw new Error('An error has occured while creating manager');
        }
        this.props.handleClose();
        return {};
      }
    ).then(
      dataJSON => {
        switch(true) {
          case ('email' in dataJSON) :
            alert('User manager with this email already exists.');
            return;
          case ('photo' in dataJSON) :
            console.log(dataJSON);
            alert('No photo was submitted.');
            return;
          default:
            break;
        }
      }
    ).catch(alert);

  }

  handlePhotoUpload(e) {
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = e.target.files[0].name;
    const imageExtension = imageName.split('.').pop();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Attach a picture.');
      return;
    }
    this.setState(() => ({
      photoObject: {file: e.target.files[0]},
      photoPreview: URL.createObjectURL(e.target.files[0]),
    }));
  }

  render() {
    const { name, phone, email, photoObject, photoPreview } = this.state;
    return(
      <>
        <div className="blur__background">
        </div>
        <div className="manager-form">
          <div className="header">
            <div
              className="close-button"
              onClick={() => this.props.handleClose()}
            ></div>
          </div>
          <div className="title">Add new manager</div>
          <form
            action="*"
            className="form"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="label">
              Photo
            </div>
            <label
              htmlFor="photo"
              className="label-photo"
              style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : 'none'}}
            >
              {
                !photoPreview &&
                <div className="plus-icon"></div>
              }
            </label>
            <input
              type="file"
                name="photo"
                id="photo"
                className="input-photo"
                onChange={(e) => this.handlePhotoUpload(e)}
              />
            <div className="label">
              Name
            </div>
            <input
              type="text"
              className="input-name text-input"
              placeholder="Name"
              required
              value={name}
              onChange={(e) => this.setState({name: e.target.value})}
            />
            <div className="label">
              Phone
            </div>
            <input
              type="text"
              className="input-phone text-input"
              placeholder="Phone"
              required
              value={phone}
              onChange={(e) => this.setState({phone: e.target.value})}
            />
            <div className="label">
              Email
            </div>
            <input
              type="email"
              className="input-email text-input"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => this.setState({email: e.target.value})}
            />
            <div className="label">
              Access
            </div>
            <div className="access-container">
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.user}
                  onChange={(e) => this.setState({user: e.target.checked})}
                />
                <div className="checkbox-label">Users</div>
              </div>
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.ads}
                  onChange={(e) => this.setState({ads: e.target.checked})}
                />
                <div className="checkbox-label">Ads</div>
              </div>
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.capture}
                  onChange={(e) => this.setState({capture: e.target.checked})}
                />
                <div className="checkbox-label">Capture</div>
              </div>
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.message}
                  onChange={(e) => this.setState({message: e.target.checked})}
                />
                <div className="checkbox-label">Message</div>
              </div>
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.settings}
                  onChange={(e) => this.setState({settings: e.target.checked})}
                />
                <div className="checkbox-label">Settings</div>
              </div>
              <div className="access-item">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={this.state.manager}
                  onChange={(e) => this.setState({manager: e.target.checked})}
                />
                <div className="checkbox-label">Managers</div>
              </div>
            </div>
            <button type="submit" className="submit-button">Save</button>
          </form>
        </div>
      </>
    )
  }
}

export default ManagerForm;