import ManagerToken from "./ManagerToken";
const BASE_URL = 'https://api.fishtools.app/admins/coefficient/';

async function getCoefficient() {
  const get = () => {
    return fetch(BASE_URL, {
      method: 'GET',
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      }
    })
  }
  return await get();
}

async function editCoefficient(body) {
  const edit = () => {
    return fetch(BASE_URL, {
      method: "PATCH",
      mode: 'cors',
      cache: 'default',
      headers: {
        "Authorization":  ManagerToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  return await edit();
}

export {getCoefficient, editCoefficient};