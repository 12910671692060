import React from "react";
import './SettingsList.scss';
import { getCoefficient } from '../../API/Settings.js';
import SettingsItem from "./SettingsItem/SettingsItem";

class SettingsList extends React.Component {
  state = {
    settingsObj: {},
    searchQuery: '',
  }

  setSearchQuery(e) {
    this.setState({searchQuery: e.target.value});
  }

  componentDidMount() {
    this.downloadSettings();
  }

  downloadSettings() {
    const response = getCoefficient();
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading settings.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState({settingsObj: dataJSON});
      }
    ).catch(alert);
  }

  searchCoefficient() {
    const keys = Object.keys(this.state.settingsObj);
    return keys.filter(key => key.toLocaleLowerCase().includes(this.state.searchQuery.toLocaleLowerCase()));
  }


  render() {
    const { settingsObj } = this.state;
    const currentData = this.searchCoefficient();

    return(
      <div className="settings-list">
        <div className="header">
          <input
            type="search"
            className="header__search"
            placeholder="Search all..."
            value={this.state.searchQuery}
            onChange={(e) => this.setSearchQuery(e)}
          />
        </div>
        <div className="list">
          <div className="list__header">
            <div className="title">Settings</div>
          </div>
          <div className="grid">
            {
              currentData.map(key => {
                return (
                  <SettingsItem obj={key} coef={settingsObj[key]}/>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsList;