import React from "react";
import './DeletionForm.scss';
import { deleteManager } from "../../../API/ManagerList";
import classNames from "classnames";

class DeletionForm extends React.Component {

  state = {
    isSuccessfull: false,
  }

  handleDelete() {
    const response = deleteManager(this.props.id);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('A problem has occurred while deleting manager.')
        }
        this.setState({isSuccessfull: true});
      }
    ).catch(alert);
  }

  render() {
    return(
      <>
        <div className="blur__background">
        </div>
        <div className={classNames("deletion-form", {'deletion-success': this.state.isSuccessfull})}>
         {
           this.state.isSuccessfull
           ?  <>
                <div className="header">
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <div className="title">Done</div>
                <div className="text">The manager has been <br/>successfully deleted.</div>
              </>
           :  <>
                <div className="header">
                  <div
                    className="close-button"
                    onClick={() => this.props.handleClose()}
                  ></div>
                </div>
                <div className="title">Delete manager</div>
                <div className="text">Are you sure you want to delete this manager's account?</div>
                <div className="buttons">
                  <div
                    className="confirmation button"
                    onClick={() => this.handleDelete()}
                  >Yes, delete</div>
                  <div
                    className="deny button"
                    onClick={() => this.props.handleClose()}
                  >No</div>
                </div>
              </>
         }
        </div>
      </>
    )
  }
}

export default DeletionForm;