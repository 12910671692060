import React from "react";
import './CaptureList.scss';
import getCaptures from '../../API/Capture.js';

class CaptureList extends React.Component {
  state = {
    searchQuery: '',
    country: '',
    date: '',
    captureList: [],
    countriesList: [],
  }

  componentDidMount() {
    this.dowloadCaptures();
  }

  setSearchQuery(e) {
    this.setState({searchQuery: e.target.value});
    this.dowloadCaptures(e.target.value);
  }

  changeCountry(e) {
    this.setState({country: e.target.value});
    this.dowloadCaptures(this.state.searchQuery, e.target.value);
  }

  changeDate(e) {
    this.setState({date: e.target.value});
    this.dowloadCaptures(this.state.searchQuery, this.state.country, e.target.value);
  }

  getCountries(arr) {
    const countriesArr = [];

    arr.forEach(element => {
        countriesArr.push(element.country);
    });
    const uniq = [...new Set(countriesArr)];

    this.setState({countriesList: uniq});
  }

  dowloadCaptures(search = this.state.searchQuery, country = this.state.country, date = this.state.date) {
    const response = getCaptures(`?q=${search}&country=${country}&dt=${date}`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while downloading the list.');
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        if (dataJSON.length) {
          this.setState({captureList: dataJSON});
          this.getCountries(dataJSON);
        } else {
          this.setState({captureList: []});
        }
      }
    ).catch(alert);
  }

  render() {
    return (
      <div className="capture-list">
        <div className="header">
          <input
            type="search"
            className="header__search"
            placeholder="Search all..."
            value={this.state.searchQuery}
            onChange={(e) => this.setSearchQuery(e)}
          />
        </div>
        <div className="list">
          <div className="list__header">
            <div className="title">Capture</div>
            <div className="filters">
              <input
                type="date"
                className="date-filter"
                value={this.state.date}
                onChange={(e) => this.changeDate(e)}
              />
              <select
                className="country-select"
                value={this.state.country}
                onChange={(e) => this.changeCountry(e)}
              >
                <option value="">Country</option>
                {
                  this.state.countriesList.map(country => {
                    return(
                      <option value={country}>{country}</option>
                    );
                  })
                }
              </select>
            </div>
          </div>
          <div className="captures">
            {
              this.state.captureList.map(item => {
                return(
                  <div className="captures__item">
                    <div className="location-info">
                      <div className="date-time">{`${item.date.split('-').reverse().join('.')}, ${item.localtime.split(' ').pop()}`}</div>
                      <div className="country">{item.country}</div>
                      <div className="city">{item.name}</div>
                      <div className="additional-info">
                        <div className="coordinates">{`${item.lat}, ${item.lon}`}</div>
                        <div className="email">{item.email}</div>
                      </div>
                    </div>
                    <div className="info-container">
                      <div className="parameters-info">
                        <div className="parameter">Length:<span className="value">{item.length ? item.length : '-'} cm</span></div>
                        <div className="parameter">Width:<span className="value">{item.width ? item.width : '-'} cm</span></div>
                        <div className="parameter">Weight:<span className="value">{item.weight ? item.weight : '-'} kg</span></div>
                      </div>
                      <div className="weather-info">
                        <div className="weather-info--left">
                          <div
                            className="photo"
                            style={{backgroundImage: `url(${item.condition_icon})`}}
                          ></div>
                          <div className="text">
                            {item.condition_text}
                          </div>
                        </div>
                        <div className="weather-info--right">
                          <div className="temperature">{item.avgtemp_c}<sup>o</sup></div>
                          <div className="wind">Wind <span className="value">{item.maxwind_kph} km/h</span></div>
                          <div className="humidity">Humidity <span className="value">{item.avghumidity}%</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default CaptureList;